import '../../fonts/Alta_caption.css';
import './header-text.css';

function handleHeaderText() {
  if (!document.readyState === 'complete' || !window.jQuery) {
    setTimeout(handleHeaderText, 60); // try again
    return;
  }

  // Single masterclass landing page
  $('#section-1574444869293 .section').append(
    "<div class='head-video-overlay-gradient'></div>",
  );

  // Homepage desktop
  // OLD version
  $('#section-1644230423705 .section').append(
    "<div class='head-video-overlay-gradient'></div>",
  );

  // NEW version as of Nov. 2022
  $('#section-1668630803538 .section').append(
    "<div class='head-video-overlay-gradient'></div>",
  );

  //Todo Check where this is
  $('#section-1611841581880 .section').append(
    "<div class='head-video-overlay-gradient-mobile'></div>",
  );

  // Homepage Mobile
  $('#section-1644083427225 .section').append(
    "<div class='head-video-overlay-gradient-mobile'></div>",
  );

  // Desktop Library
  $('#section-1628280261719 .section').append(
    "<div class='head-video-overlay-gradient'></div>",
  );

  // Mobile Library
  $('#section-1628280960572 .section').append(
    "<div class='head-video-overlay-gradient'></div>",
  );

  // On Landing Page: Daniil etc. Mobile
  $('#section-1607373639264 .section').css('position', 'relative');
  $('#section-1607373639264 .section').prepend(
    "<div class='head-video-overlay-gradient'></div>",
  );

  // On Shop Page: Daniil etc.
  $('#block-1574444874481').css('position', 'relative');
  $('#block-1574444874481').css('z-index', 5);

  $('.backgroundVideo').siblings().css('z-index', 5);
}

export default handleHeaderText;
