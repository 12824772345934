const clm = require('country-locale-map');
const isEuMember = require('is-eu-member').isEuMember;
import Cookie from 'cookie-universal';

const cookies = Cookie();

window.DanceMasterclass = window.DanceMasterclass || {};

import './global-style.css';

import handleHeaderText from './header-text/header-text';
import handleFooterUrls from './footer-urls/footer-urls';
import handleVideoUploadForms from './video-upload-forms/video-upload-forms';
import handleStoreTweaks from './store-tweaks/store-tweaks';

// run scripts
handleHeaderText();
handleFooterUrls();
handleStoreTweaks();

const savedGeoLocationData = cookies.get('geolocation');
if (savedGeoLocationData && savedGeoLocationData.country_code) {
  onGeolocationData(savedGeoLocationData);
} else {
  fetch('https://api.vatcomply.com/geolocate')
    .then((response) => response.json())
    .then((data) => onGeolocationData(data, true))
    .catch((error) => {
      console.error('GeoLocation API Error', error);
    });
}

function onGeolocationData(data, fromApi) {
  if (data && data.country_code) {
    if (fromApi) {
      cookies.set('geolocation', data, {
        expires: new Date(new Date().getTime() + 1000 * 3600 * 24 * 7), // a week
      });
    }
    window.DanceMasterclass.country = clm.getCountryByAlpha2(data.country_code);
    window.DanceMasterclass.country.isEuMember = isEuMember(data.country_code);

    handleVideoUploadForms();
  }
}
