import './store-tweaks.css';

function handleStoreTweaks() {
  if (!window.location.href.includes('store')) {
    return;
  } else if (!document.readyState == 'complete' || !window.jQuery) {
    setTimeout(handleStoreTweaks, 30); // try again
    return;
  }

  // store header does not have a class but should be the first section in main
  const header = $('main').find('div').first().find('div').first();

  if (!header) {
    return;
  }
  header.css('position', 'relative');
  header.prepend("<div class='store-header-overlay-gradient'></div>");

  // equal size of the pricing boxes
  $('.hidden--mobile .pricing')
    .first()
    .parent()
    .parent()
    .parent()
    .addClass('pricing-container');

  // Add Most Popular Div
  const pricingContainers = $('.pricing-container').find(
    '.block-type--pricing',
  );
  if (pricingContainers && pricingContainers.length > 1) {
    const popularPricingContainer = pricingContainers.eq(1);
    const bestValuePricingContainer = pricingContainers.eq(2);

    if (popularPricingContainer) {
      popularPricingContainer.prepend(
        "<div class='pricing-tag pricing-tag--most-popular'>Most Popular</div>",
      );
    }

    if (bestValuePricingContainer) {
      bestValuePricingContainer.prepend(
        "<div class='pricing-tag pricing-tag--best-value'>Best Value (Save 30%)</div>",
      );
    }
  }

  // Add Most Popular Div for Mobile
  const pricingContainersMobile = $('.hidden--desktop').find(
    '.block-type--pricing',
  );

  pricingContainersMobile.addClass('pricing-container-mobile');

  if (pricingContainersMobile && pricingContainersMobile.length > 1) {
    const popularPricingContainerMobile = pricingContainersMobile.eq(0);
    const bestValuePricingContainer = pricingContainersMobile.eq(1);

    if (popularPricingContainerMobile) {
      popularPricingContainerMobile.prepend(
        "<div class='pricing-tag-mobile pricing-tag--most-popular-mobile'>Most Popular</div>",
      );
    }

    if (bestValuePricingContainer) {
      bestValuePricingContainer.prepend(
        "<div class='pricing-tag-mobile pricing-tag--best-value-mobile'>Best Value (Save 30%)</div>",
      );
    }
  }
}

export default handleStoreTweaks;
