import './video-upload-forms.css';
import './video-upload-template.css';
import VideoUploadTemplate from './video-upload-template.html';

/* Holds the form input for video url */
let videoUrlInput;

function handleVideoUploadForms() {
  if (!document.readyState === 'complete' || !window.jQuery) {
    setTimeout(handleVideoUploadForms, 60); // try again
    return;
  }

  // check if page has an form with video input
  let videoUrlLegend = $('legend:contains("VIDEO_URL")');
  if (videoUrlLegend && videoUrlLegend.length) {
    videoUrlLegend = videoUrlLegend.first();
    const videoUrlFieldSet = videoUrlLegend.parent().first();
    videoUrlInput = videoUrlFieldSet.find('input').first();

    videoUrlInput.hide();
    videoUrlLegend.hide();

    // Read out users email from facebook pixel script
    var match = $('head')
      .html()
      .match(/"em":"(.*?)"/);
    // console.log(match);
    let userMail = 'unknown_mail';
    if (match && match[1] && match[1].length) {
      userMail = match[1];
    }

    console.log('User Mail', userMail, 'videoUrlFieldSet', videoUrlFieldSet);

    const feedbackForm = videoUrlFieldSet.closest('form').first();

    feedbackForm.addClass('feedback-form');

    videoUrlFieldSet.append(VideoUploadTemplate);

    initVideoUploadField();

    feedbackForm.on('submit', function (e) {
      // prevent resubmitting
      if (window.history.replaceState) {
        window.history.replaceState(null, null, window.location.href);
      }

      const finalNotes =
        $('.help-block').last().text() ||
        'The Master will carefully watch and analyze your video and personal information. He will take time to give you useful and relevant feedback. He will write down detailed advice and corrections and make a personal video for you. All this takes time. You will hear from the master in 4 weeks at the latest.';

      feedbackForm.after(
        `<h2>Form submitted successfully!</h2><p>${finalNotes}</p>`,
      );
      feedbackForm.hide();
    });
    // videoUrlFieldSet.show();

    /*googleFormsIFrame.parent().append(VideoUploadTemplate);

    googleFormsIFrame.hide();*/
  }
}

function initVideoUploadField() {
  // https://api.video/blog/tutorials/delegated-uploads
  const delegatedToken = 'to62mqA5SWIviIOlBUjXu1V4';
  const input = document.querySelector('#video-file');
  const url = 'https://ws.api.video/upload?token=' + delegatedToken;
  var chunkCounter = 0;
  //break into 1 MB chunks for demo purposes
  const chunkSize = 1000000;
  var videoId = '';
  var playerUrl = '';

  let videoFileHeading = 'Upload your Video';
  let videoFileButtonCaption = 'Click here to upload a video';
  let uploadedCaption = 'Uploaded:';
  let successfullyUploadedCaption = 'Video successfully uploaded!';

  if (
    window.DanceMasterclass &&
    window.DanceMasterclass.country &&
    window.DanceMasterclass.country.alpha2 === 'JP'
  ) {
    videoFileHeading =
      '以下のボタンをクリックして動画をアップロードして下さい。';
    videoFileButtonCaption = '動画をアップロード';
    uploadedCaption = 'アップロード:';
    successfullyUploadedCaption = '無事に動画がアップロードされました。';
  }

  document.getElementById('video-file-head').innerHTML = videoFileHeading;
  document.getElementById('video-file-label').innerHTML =
    videoFileButtonCaption;

  input.addEventListener('change', () => {
    const file = input.files[0];
    const filename = input.files[0].name;
    var numberofChunks = Math.ceil(file.size / chunkSize);
    var start = 0;
    chunkCounter = 0;
    videoId = '';
    var chunkEnd = start + chunkSize;
    //upload the first chunk to get the videoId
    createChunk(videoId, start);

    function createChunk(videoId, start, end) {
      chunkCounter++;
      // console.log("created chunk: ", chunkCounter);
      chunkEnd = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, chunkEnd);
      /*console.log(
        "i created a chunk of video" + start + "-" + chunkEnd + "minus 1	"
      );*/
      const chunkForm = new FormData();
      if (videoId.length > 0) {
        //we have a videoId
        chunkForm.append('videoId', videoId);
        // console.log("added videoId");
      }
      chunkForm.append('file', chunk, filename);
      // console.log("added file");

      //created the chunk, now upload iit
      uploadChunk(chunkForm, start, chunkEnd);
    }

    function uploadChunk(chunkForm, start, chunkEnd) {
      var oReq = new XMLHttpRequest();
      oReq.upload.addEventListener('progress', updateProgress);
      oReq.open('POST', url, true);
      var blobEnd = chunkEnd - 1;
      var contentRange = 'bytes ' + start + '-' + blobEnd + '/' + file.size;
      oReq.setRequestHeader('Content-Range', contentRange);
      // console.log("Content-Range", contentRange);
      function updateProgress(oEvent) {
        if (oEvent.lengthComputable) {
          var percentComplete = Math.round(
            (oEvent.loaded / oEvent.total) * 100,
          );

          var totalPercentComplete = Math.round(
            ((chunkCounter - 1) / numberofChunks) * 100 +
              percentComplete / numberofChunks,
          );
          document.getElementById('chunk-information').innerHTML =
            `${uploadedCaption} ${totalPercentComplete}%`;
          document.getElementById('upload-button-container').style.display =
            'none';
          //	console.log (percentComplete);
          // ...
        } else {
          console.log('not computable');
          document.getElementById('upload-button-container').style.display =
            'inline-block';
          // Unable to compute progress information since the total size is unknown
        }
      }
      oReq.onload = function (oEvent) {
        // Uploaded.
        console.log('uploaded chunk');
        console.log('oReq.response', oReq.response);
        var resp = JSON.parse(oReq.response);
        videoId = resp.videoId;
        //playerUrl = resp.assets.player;
        console.log('videoId', videoId);

        //now we have the video ID - loop through and add the remaining chunks
        //we start one chunk in, as we have uploaded the first one.
        //next chunk starts at + chunkSize from start
        start += chunkSize;
        //if start is smaller than file size - we have more to still upload
        if (start < file.size) {
          //create the new chunk
          createChunk(videoId, start);
        } else {
          //the video is fully uploaded. there will now be a url in the response
          document.getElementById('upload-button-container').style.display =
            'none';
          document.getElementById('chunk-information').innerHTML =
            successfullyUploadedCaption;

          playerUrl = resp.assets.player;
          // console.log("all uploaded! Watch here: ", playerUrl);
          if (videoUrlInput) {
            videoUrlInput.val(playerUrl);
          }
        }
      };
      oReq.send(chunkForm);
    }
  });
}

export default handleVideoUploadForms;
