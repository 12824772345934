import './footer-urls.css';

function handleFooterUrls() {
  if (!document.readyState === 'complete' || !window.jQuery) {
    setTimeout(handleFooterUrls, 60); // try again
    return;
  }

  $('a')
    .filter(function () {
      const externalUrlsRegex =
        /((http|https):\/\/(?!(www.)?dance-masterclass.com)[\w\.\/\-=?#]+)/;
      if ($(this).attr('href')) {
        return $(this).attr('href').match(externalUrlsRegex);
      } else {
        return false;
      }
    })
    .attr('target', '_blank');
}

export default handleFooterUrls;
